<table class="" *ngIf="tableData && tableData.length; else noData">
  <thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col" sortable="name"  (sort)="onSort($event)">name</th>
    <th scope="col" sortable="createdAt"  (sort)="onSort($event)">Date</th>
    <th scope="col" sortable="type"  (sort)="onSort($event)">Type</th>
    <th scope="col">Playlist</th>
    <th scope="col">Download Link</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let record of tableRows; let i = index" (click)="open($event, record)" [class.clickable]="record.isPlaylist">
    <th scope="row">{{ i+1 }}</th>
    <td  data-label="Name">
      {{ record.youtubePlaylistApiName ?  record.youtubePlaylistApiName : record.name }} {{ record.isPlaylist ? "("+ (record.itemsCount ? record.itemsCount : 0) +")" : "" }}
    </td>
    <td data-label="Date">
      {{ record.createdAt | date:'short' }}
    </td>
    <td  data-label="Type">
      {{ record.type }}
    </td>
    <td  data-label="Playlist">
      {{ record.isPlaylist ? 'Yes' : 'No' }}
    </td>
    <td  data-label="Download">
      <button type="button" class="btn btn-info" (click)="download(record.download_link)"> Download</button>
     </td>
  </tr>
  </tbody>
</table>
<ng-template #noData>
  <div class="no-records">
    No Records found
  </div>
</ng-template>
