import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from './auth/models/user';

@Injectable({providedIn: 'root'})
export class UsersService {
  users: User[] = [];
  total: number;

  users$ = new BehaviorSubject<UsersResponse>({data: [], meta: [], actions: []})
  identityUrl = 'http://console.akkovachev.com:3100/api'

  constructor(private httpClient: HttpClient) {}

  getUsers() {
   return this.httpClient.get<UsersResponse>(`${this.identityUrl}/admin/users`).toPromise().then(d => {
     this.users$.next(d);
   })
  }

  async executeAction(action: any, partialUrl: string, payload: any) {
    let result;
    switch(action.httpType) {
      case 'DELETE':
        result = await this.httpClient.delete(this.identityUrl + partialUrl).toPromise()
      break;
      case 'POST':
        result = await this.httpClient.post(this.identityUrl + partialUrl, payload).toPromise()
    }

    return result;
  }
}

interface UsersResponse {
  data: any[],
  meta: any[],
  actions: any[]
}


