import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MetaOptions } from 'src/app/auth/models/meta.model';
import { CheckBox } from './checkbox';
import { TaskFormBase } from './task-base';
import { TextboxField } from './text';

@Injectable()
export class MetaService {
  constructor() {}

  toFormGroup(tasks: TaskFormBase<string>[]) {
    const group: any = {};
    tasks.forEach((task) => {
      group[task.key] = task.required
        ? new FormControl(task.value || '', Validators.required)
        : new FormControl(task.value || '');
    });
    return new FormGroup(group);
  }

  buildMetaBase(meta: MetaOptions[], values: Object) {
    let metArray = [];
    meta.forEach(m => {
      if(m.type === 'TEXT' || m.type === 'COMMA_SEPARATED') {
        metArray.push(new TextboxField({
          label: m.label,
          key: m.property,
          type: 'text',
          value: values[m.property] || ''
        }))
      } else if(m.type === 'BOOLEAN') {
        metArray.push(new CheckBox({
          label: m.label,
          key: m.property,
          value: values[m.property]
        }))
      }
    })

    return metArray;
  }
}
