import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TaskResponseStatus } from '../models/TaskResponseStatus';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  baseUrl = 'https://console.akkovachev.com/api';

  constructor(private http: HttpClient) {}

  startTask(payload) {
    return this.http.post(`${this.baseUrl}/task`, payload).toPromise();
  }

  getTaskStatus() {
    return this.http.get<TaskResponseStatus[]>(`${this.baseUrl}/tasks`)
      .pipe(
      map(items => items.sort((a,b) => {
      return  new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
    })))
  }

  getTaskLog(taskId) {
    return this.http.get<{content: string, status: string}>(`${this.baseUrl}/task/log/${taskId}`).toPromise();
  }
}
