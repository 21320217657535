import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-silent-login',
  templateUrl: './silent-login.component.html',
  styleUrls: ['./silent-login.component.scss']
})
export class SilentLoginComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(d => {
      if(d.code) {
        this.auth.loginSilently(d.code);
      } else {
        this.router.navigate(['/login'])
      }
    })
  }
}
