<div class="d-flex flex-wrap align-items-center justify-content-center">
  <h1 class="jumbotron-heading text-center">Потребители</h1>
  <table class="table table-striped">
    <thead class="thead-dark">
    <tr>
      <th scope="col" *ngFor="let header of tableHeaders">
        {{header}}
      </th>
      <th scope="col" >
       Actions
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let userData of usersData; let i = index">
      <td scope="row" *ngFor="let d of userData">{{d }}</td>
      <td>
         <app-action [label]="'User Actions'" [actions]="actions" [data]="dataRef[i]" (onActionExecute)="cleanData($event)"></app-action>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-2">
    <!-- <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="refreshCountries()">
    </ngb-pagination> -->

    <!-- <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
      <option [ngValue]="2">2 items per page</option>
      <option [ngValue]="4">4 items per page</option>
      <option [ngValue]="6">6 items per page</option>
    </select> -->
  </div>
</div>
