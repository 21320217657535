import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AlertsService, AlertTypes } from '../alerts.service';
import { AuthService } from '../auth/auth.service';
import { YoutubeService } from '../youtube.service';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss']
})
export class YoutubeComponent implements OnInit {

  content: string;
  inProgress = false;
  showDownload = false;
  download_link: string;
  isCollapsed = true;
  isCollapsedRecent = true;
  socketError = false;

  tableData: any[]

  socketSubject$: Subscription;

  constructor(private youtubeService: YoutubeService, private auth: AuthService, private alerts: AlertsService) {}

  youtubeData: {
    link?: string,
    folder?: string,
    type: boolean
    userId?: number
    syncWithMyDir:boolean
  } = {
    folder: '',
    link: '',
    type: false,
    syncWithMyDir: false
  }

  private resetStates() {
    this.inProgress = false;
    this.showDownload = false;
  }


  startConversion() {
    this.showDownload = false
    this.inProgress = true;

    const req: any = {...this.youtubeData}

    if(this.youtubeData.type) {
      req.type = 'MP3'
    } else {
      req.type = 'VIDEO'
    }

    this.youtubeService.initiateDownload(req).then(d => {
      this.youtubeService.sendMessage({"id": d.id})
    }).catch(e => {
      this.alerts.addAlert(e, AlertTypes.ERROR)
      this.resetStates();
    })
  }

  downloadFile() {
    window.open(this.download_link.replace('http', 'https'));
  }

  ngOnInit(): void {
    this.youtubeService.connect();
    this.auth.getCurrentUser().then(d => this.youtubeData.userId = d.id)

    this.socketSubject$ = this.youtubeService.messages$.pipe(
      map(rows => {
        return rows as any
      }),
      catchError(error => {console.log(error); throw error }),
      tap({
        error: error => console.log('[Live Table component] Error:', error),
        complete: () => console.log('[Live Table component] Connection Closed')
      })
    ).subscribe(d => {
      if(d.console) {
             this.socketError = false;
          } else {

            if(d.error) {
              this.socketError = true;
            }

            if(d && !d.ping && d.logReadInProgress) {
              this.content = d.content;
              this.inProgress = true;
            }

            if(d && d.download_link && !d.inProgress) {
              this.showDownload = true;
              this.inProgress = false;
              this.download_link = d.download_link
              this.loadRecentDownloads()

              if(d.content) {
                this.content = d.content
              }
            }
          }
    },(e) => {
      console.log(e)
    });

  }


  loadRecentDownloads() {
    this.youtubeService.getDownloads().then(d => {
      this.tableData = d;
    }).catch(e => this.socketError = true)
  }

  ngOnDestroy() {
    this.socketSubject$.unsubscribe()
    this.youtubeService.close()
  }

}
