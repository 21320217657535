import { Component, Input, OnInit } from '@angular/core';
import { YoutubeService } from 'src/app/youtube.service';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  @Input() content: any;
  constructor(private youtube: YoutubeService) { }

  tableRows = []

  ngOnInit(): void {

    if(this.content.isPlaylist) {
      this.youtube.getListItemDetails(this.content.youTubeId).then(d => {
        this.tableRows = d;
      })
    }
  }

}
