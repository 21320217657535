<nav class="site-header sticky-top py-1" *ngIf="headerData$ | async as hData">
  <div class="container d-flex flex-column flex-md-row">
    <div class="d-flex home">
      <a class="py-2 " routerLink="/home">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="d-block mx-auto"><circle cx="12" cy="12" r="10"></circle><line x1="14.31" y1="8" x2="20.05" y2="17.94"></line><line x1="9.69" y1="8" x2="21.17" y2="8"></line><line x1="7.38" y1="12" x2="13.12" y2="2.06"></line><line x1="9.69" y1="16" x2="3.95" y2="6.06"></line><line x1="14.31" y1="16" x2="2.83" y2="16"></line><line x1="16.62" y1="12" x2="10.88" y2="21.94"></line></svg>
      </a>
      <button class="showOnMobile" (click)="toggle()">close</button>
    </div>
    <div class="menu-items" [class.hidden]="menuHidden">
      <a class="py-2" routerLink="/tasks" canSeeIfHasPermission="tasks">Задачи</a>
      <a class="py-2" routerLink="/sensors" canSeeIfHasPermission="sensors">Сензори температура</a>
      <!-- <a class="py-2 mr-auto " routerLink="/users">Потребители</a> -->
      <a class="py-2" routerLink="/youtube" canSeeIfHasPermission="youtube">YouTube</a>
      <a class="py-2 mr-auto " routerLink="/plex" canSeeIfHasPermission="youtube">Plex</a>
      <span>Здравей {{hData.user}}, <a (click)="logout()">Изход</a></span>
    </div>
  </div>
</nav>
