import { Subject, BehaviorSubject } from 'rxjs';
import { PBTorrentEntry } from './../models/torrents.model';
import { SearchEntry } from './../models/searchEntry.model';
import { PlexService } from './../plex.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plex',
  templateUrl: './plex.component.html',
  styleUrls: ['./plex.component.scss']
})
export class PlexComponent implements OnInit {

  plexSearch: {
    serachPhrase:string
  } = {
    serachPhrase: ''
  }

  isSearching = false;
  isCollapsedSearchResult = true;
  isCollapsedProgress = true;

  torrentsData$: BehaviorSubject<PBTorrentEntry[]> = new BehaviorSubject<PBTorrentEntry[]>([])

  searchResults: SearchEntry[];

  constructor(private plexService: PlexService) {}

  ngOnInit(): void {
    this.plexService.subscribeToTorrentsCollection()
    this.plexService.getTorrents();

    this.torrentsData$ = this.plexService.torrents$

  }

  async search() {
    this.isSearching = true;
    this.isCollapsedSearchResult = false;
    this.searchResults = [];
    let searchResults = await this.plexService.search(this.plexSearch.serachPhrase)

    this.isSearching = false

    this.searchResults = searchResults
  }


}
