import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../data.service';
import { TaskResponseStatus } from '../models/TaskResponseStatus';
import { TaskService } from '../services/task.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private taskService: TaskService,
    private modalService: NgbModal
  ) {}

  tasks$;
  status$: Observable<TaskResponseStatus[]>;
  closeResult = '';
  logFile = '';
  selectedTask: TaskResponseStatus;

  responsiveTableHeaders = ['Задача', 'Стартиранта на', 'Статус', 'Приключила на', 'Лог Файл']

  ngOnInit(): void {
    this.tasks$ = this.dataService.getTaskMetadata();
    this.status$ = this.taskService.getTaskStatus();
  }

  async open(task: TaskResponseStatus, content) {
    this.selectedTask = task;
    try {
      let getLog = await this.taskService.getTaskLog(task.id)
      this.logFile = getLog.content;
    } catch(e) {
      console.log(e);
    }

    this.modalService.open(content, {windowClass: "tasksModal"}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
