import { PBTorrentEntry } from './../../models/torrents.model';
import { SearchEntry } from '../../models/searchEntry.model';
import { Component, Directive, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';

export type SortColumn = keyof SearchEntry | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: {[key: string]: SortDirection} = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class NgbdSortableHeader {
  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }
}

@Component({
  selector: 'completed-pex-table',
  templateUrl: './completed-table.component.html',
  styleUrls: ['./completed-table.component.scss']
})
export class CompletedPlexTable implements OnInit {
  @Input() tableData: PBTorrentEntry[]
  tableRows: PBTorrentEntry[];

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.tableRows = this.tableData;
    } else {
      this.tableRows = [...this.tableData].sort((a, b) => {
        let compValueA = a[column];
        let compValueB = b[column];
        const res = compare(compValueA, compValueB);

        return direction === 'asc' ? res : -res;
      });
    }
  }

  convertValue(value: string):number {
    if(value.includes('MB')) {
      return parseFloat(value)
    }
    if(value.includes('GB')) {
      return parseFloat(value) * 1024
    }

    return parseInt(value)
  }

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if(this.tableData && this.tableData.length) {
      this.tableRows = this.tableData
    }
  }

  download(torrentId: string) {
    console.log('torrentId', torrentId)
  }
}
