import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { User } from '../auth/models/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }
  headerData$: Observable<User>;
  menuHidden = false;
  ngOnInit(): void {
    this.headerData$ = this.authService.currentUser;
  }

  logout() {
    this.authService.logout().then(d => {
      this.router.navigate(['/login'])
    });
  }

  toggle() {
    this.menuHidden = !this.menuHidden
  }

}
