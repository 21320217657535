import { Component } from '@angular/core';
import { debounce } from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { AlertsService } from './alerts.service';
import { HelperUtil } from './HelperUtil';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'WebAdmin';
  alertMessages = [];

  constructor() {}

  ngOnInit() {

  }

}
