import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ignoreElements, map } from 'rxjs/operators';

import { StorageUser, User } from './models/user';
import { Router } from '@angular/router';
import { transpileModule } from 'typescript';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    loginMode = 'SILENT';
    baseApiUrl = "https://id.akovachev.com/api"
    identityServerLoginPage = "https://id.akovachev.com/login";

    loginMap = {
      'youtube': '/youtube',
      'tasks': '/tasks',
      'sensors': '/sensors',
      'all': '/tasks'
    }

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    getCurrentUser(): Promise<StorageUser> {
      let checkIfUserInSession = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser') ) as StorageUser : undefined
      if(checkIfUserInSession && !checkIfUserInSession.id) {
        return this.http.get<any>(`${this.baseApiUrl}/current`).toPromise()
      } else {
        return new Promise(resolve => resolve(JSON.parse(localStorage.getItem('currentUser'))))
      }
    }

    loginSilently(base64String: string) {
      let base64DecodedString = atob(base64String);
      localStorage.setItem('currentUser', base64DecodedString);
      this.currentUserSubject.next(JSON.parse(base64DecodedString) as User)

      //Not nice but it will do the job :D
      this.getCurrentUser().then(d => {
        let getCurrent = JSON.parse(localStorage.getItem('currentUser'));
        getCurrent.id = d.id;
        getCurrent.scopes = d.scopes;
        localStorage.setItem('currentUser', JSON.stringify(getCurrent));

        this.navigateToCorrectRoute(d.scopes)
      })
    }

    navigateToCorrectRoute(scopes?: string) {

      //Just set the scopes if non are set
      if(!scopes) {
        let getCurrent = JSON.parse(localStorage.getItem('currentUser'));
        scopes = getCurrent.scopes
      }

      if(scopes.indexOf('ALL') != -1) {
        this.router.navigate([this.loginMap['all']])
      } else {
        //ALways redirect to first route in the scopes
        let scopesArr = scopes.split(',')
        this.router.navigate([this.loginMap[scopesArr[0]]])
      }
    }

    login(username: string, password: string, TFAToken: string) {
        return this.http.post<any>(`${this.baseApiUrl}/login`, { username, password,  TFAToken})
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));

                this.currentUserSubject.next(user);
                return user;
            }));
    }

    async logout(error?) {
        // remove user from local storage to log user out
        try {
            if(!error) {

              let logoutServer = await this.http.delete(`${this.baseApiUrl}/logout`,{params: {token: this.currentUserValue.token}}).toPromise()
              return logoutServer;
            } else {
              localStorage.removeItem('currentUser');
              this.currentUserSubject.next(null);
              this.router.navigate(['/login'])
            }
        } catch(e) {
          console.log(e);
        }
    }

    setLoginMode(mode: 'SILENT' | 'DIRECT') {
      this.loginMode = mode;
    }

    checkCurrentUserRoutes(url: string): boolean {
      let getUserScopes: StorageUser = JSON.parse(localStorage.getItem('currentUser'));

      //If Undefined Force redirect to login
      if(!getUserScopes) {
        return false
      }

      let currentUserScopesArr = getUserScopes.scopes.split(',')

      if(getUserScopes.scopes.toLowerCase().indexOf('all') != -1) {
        return true;
      }

      //Always allow Home
      if(url.indexOf('home') != -1) {
        return true;
      }

      let temp = {}
      let accessibleRoutes = currentUserScopesArr.map(e => {
        if(!temp[e.toLowerCase()]) {
          temp[e.toLowerCase()] = this.loginMap[e] ? true : false
        }
        return temp;
      })

      return accessibleRoutes[0][url];
    }

    getCurrentUserScopes(): Promise<string> {
      return this.getCurrentUser().then(d => {
        return d.scopes;
      })
    }
}
