<form (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="form">

  <div *ngFor="let field of taskFields" class="form-row form-group">
    <app-dynamic-form-field [task]="field" [form]="form"></app-dynamic-form-field>
  </div>
  <div *ngIf="taskFields.length === 1">Задачата няма параметри</div>
  <div class="form-row">
    <button type="submit" class="btn btn-primary" [disabled]="!form.valid">Изпълни</button>
  </div>
</form>
