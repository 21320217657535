import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertsService } from '../alerts.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  constructor(private alertsService: AlertsService) { }

  staticAlertClosed = false;
  successMessage = '';
  messages: any[] = []

  ngOnInit(): void {
    this.alertsService.alerts$.subscribe(message => {
      this.messages = message;
    });
  }
}
