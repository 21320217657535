import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TaskFormBase } from '../task-base';

@Component({
  selector: 'app-dynamic-form-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss']
})
export class DynamicFormField  {

  @Input() task: TaskFormBase<string>;
  @Input() form: FormGroup;
  get isValid() { return this.form.controls[this.task.key].valid; }

}
