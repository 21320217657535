import { Component, OnInit } from '@angular/core';
import { SensorResponse } from '../models/types';
import { TempService } from '../services/temp.service';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.scss']
})
export class SensorsComponent implements OnInit {

  constructor(private tempService: TempService) { }

  sensorsList: SensorResponse[];

  ngOnInit(): void {
    this.tempService.getTemperatureSensors().then(sensors => {
      this.sensorsList = sensors
    })
  }
}
