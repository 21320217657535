import { Component, Directive, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from '../info-modal/info-modal.component';


export interface YoutubeRecord {
  createdAt: Date;
  download_link: string;
  name: string
  task: any
  type: string
  updatedAt: Date
  _id: string
}

export type SortColumn = keyof YoutubeRecord | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: {[key: string]: SortDirection} = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()'
  }
})
export class NgbdSortableHeader {

  @Input() sortable: SortColumn = '';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({column: this.sortable, direction: this.direction});
  }

  ngOnInit() {
  }
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() tableData: YoutubeRecord[]
  tableRows;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    if (direction === '' || column === '') {
      this.tableRows = this.tableData;
    } else {
      this.tableRows = [...this.tableData].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }


  constructor(private modalService: NgbModal) { }

  async open(event: any, record: any) {
    const eventTarget: Element = event.target;

    if(eventTarget.tagName === 'BUTTON') {
      event.preventDefault()

      return;
    }

    if(record.isPlaylist) {
      const modalRef = this.modalService.open(InfoModalComponent, {windowClass: 'video-modal'});
      modalRef.componentInstance.content = record;
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if(this.tableData && this.tableData.length) {
      this.tableRows = this.tableData
    }
  }

  download(url: string) {
    window.open(url.replace('http', 'https'));
  }
}
