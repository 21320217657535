import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, of, Subject, Subscription } from 'rxjs';
import { HelperUtil } from './HelperUtil';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  alerts$ = new Subject<Alerts[]>();
  alertsLocal: Alerts[] = [];

  private subscription;

  constructor() {}

  addAlert(message, type: string = AlertTypes.SUCCESS) {
    this.alertsLocal.push({
      message: message,
      closeIn:  new Date().getTime() + 8000,
      id: HelperUtil.generateRandomPassword(),
      type: type
    });

    this.alerts$.next(this.alertsLocal);
    this.selfSubscribe();
  }

  selfSubscribe() {
    if (!this.subscription) {
      let source = interval(1000);
      this.subscription = source.subscribe((d) => {
        this._removeExpiredNotes();
      });
    }
  }

  _removeExpiredNotes() {
    if(this.alertsLocal.length === 0) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }

    this.alertsLocal.forEach((a, idx) => {
      if(new Date().getTime() > a.closeIn) {
        this.alertsLocal.splice(idx, 1);
      }
    })
  }
}

interface Alerts {
  id: string;
  closeIn: number;
  message: string;
  type: string
}

export enum AlertTypes {
  ERROR = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning'
}
