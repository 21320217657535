import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Sensor } from 'src/app/models/Sensor';
import { Room } from 'src/app/models/types';
import { TempService } from 'src/app/services/temp.service';

@Component({
  selector: 'app-mi-sensor',
  templateUrl: './mi-sensor.component.html',
  styleUrls: ['./mi-sensor.component.scss']
})
export class MiSensorComponent implements OnInit {
  @Input() roomName: String;
  @Input() roomEnum: Room

  temperature: String;
  humidity: String;
  voltage: String;
  isLoading = false;
  constructor(private tempService: TempService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {}

  getTemperature() {
    this.isLoading = true;
    this.tempService.getTemperatureReadingForRoom(this.roomEnum).then(sensorReading => {

      this.temperature = sensorReading.temperature;
      this.humidity = sensorReading.humidity;
      this.voltage = sensorReading.voltage;
      this.isLoading = false;
      this.cd.detectChanges();
    }).catch(e => {
      this.isLoading = false;
    })
  }

}
