import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first, windowWhen } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ILoginModel, LoginModel } from './models/login.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  userFormModel: ILoginModel = {username: '', password: '', TFAToken: '123'};
  error = '';
  showTFAForm = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }
  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    //If the Login Mode is set to Silent Redirect to Identity Server for Authorization
    if(this.authService.loginMode === 'SILENT') {
      let redirectUrl = '';
      if(window.location.hash.indexOf('#') !== -1) {
        redirectUrl = window.location.origin + '/#/'
      } else {
        redirectUrl = window.location.origin + '/'
      }

      redirectUrl+= 'silent-login'
      let prepareEncodedLink = this.authService.identityServerLoginPage + '?redirect='+ encodeURIComponent(redirectUrl);

      window.location.href=prepareEncodedLink
      console.log('redirect Url', prepareEncodedLink)
    }
  }

  login() {
        this.authService
        .login(this.userFormModel.username, this.userFormModel.password, this.userFormModel.TFAToken)
        .pipe(first())
        .subscribe(
          (data) => {
            this.router.navigate([this.returnUrl]);
          },
          (error) => {
            this.error = error;
          });
    }
}
