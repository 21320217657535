import { Directive, ElementRef, Input } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Directive({
  selector: '[canSeeIfHasPermission]'
})
export class CanSeeDirective {

  @Input() canSeeIfHasPermission: string;

  constructor(private authService: AuthService, private el: ElementRef) { }

  ngOnInit() {
    this.authService.getCurrentUserScopes().then(d => {
      if(d.toLowerCase().indexOf('all') === -1)
          if(d.toLocaleLowerCase().indexOf(this.canSeeIfHasPermission) != -1) {
            this.el.nativeElement.style.display = 'block'
          } else {
            this.el.nativeElement.style.display = 'none'
        }
    })
  }

}
