  <h1 class="jumbotron-heading text-center">Задачи</h1>
  <ngb-accordion [closeOthers]="true" activeIds="static-1">
    <ngb-panel *ngFor="let task of tasks$ | async" [title]="task.name">
      <ng-template ngbPanelContent>
        <app-dynamic-form [taskFields]="task.formFields"></app-dynamic-form>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
  <h3 class="text-center">Логове</h3>
  <div class="tasks" *ngIf="status$ | async as taskStatus">
    <table class="">
      <thead>
        <tr>
          <th scope="col">Задача</th>
          <th scope="col">Стартирана на</th>
          <th scope="col">Статус</th>
          <th scope="col">Приключила на</th>
          <th scope="col">Лог файл</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ts of taskStatus">
          <td>{{ ts.taskName }}</td>
          <td>{{ ts.startedAt | date:'medium' }}</td>
          <td>{{ ts.status }}</td>
          <td>{{ ts.endedAt | date:'medium'}}</td>
          <td><a (click)="open(ts, content)">Покажи</a></td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Лог -> {{selectedTask.taskName}} ({{selectedTask.id}})</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <pre>
      {{ logFile }}
      </pre
      >
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-dark"
        (click)="modal.close('Close')"
      >
        Close
      </button>
    </div>
  </ng-template>
