import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TaskFormBase } from './tasks/form/task-base';
import { DropdownField } from './tasks/form/dropdown';
import { TextboxField } from './tasks/form/text';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(private http: HttpClient) {}

  baseUrl = 'https://console.akkovachev.com/api';
  getTasksMetadata() {
    return this.http.get<any[]>(`${this.baseUrl}/metadata/tasks`);
  }

  async getTaskMetadata() {
    let tasksMetadata: TaskMetadata[] = [];

    let tasks = await this.getTasksMetadata().toPromise();

    tasks.forEach((task) => {
      let newTask = new TaskMetadata(task.name, [
        new TextboxField({ type: 'hidden', value: task.value, key: 'task' }),
      ]);
      if (task.args && task.args.length) {
        task.args.forEach((a) => {
          if (a.type === 'picklist') {
            newTask.formFields.push(
              new DropdownField({
                key: a.key,
                label: a.label,
                options: a.options.map((o) => {
                  return {
                    key: o,
                    value: o,
                  };
                }),
              })
            );
          }

          if(a.type === 'text') {
            newTask.formFields.push(
              new TextboxField({
                label: a.label,
                key: a.key,
                type: 'text'
              })
            )
          }
        });
      }
      tasksMetadata.push(newTask);
    });

    return tasksMetadata;
  }
}

class TaskMetadata {
  constructor(
    public name: string,
    public formFields?: TaskFormBase<string>[]
  ) {}
}
