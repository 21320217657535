import { AlertsService } from './alerts.service';
import { PBTorrentEntry } from './models/torrents.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { SearchEntry } from './models/searchEntry.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth/auth.service';
// import PocketBase from 'pocketbase';
//@ts-ignore
import PocketBase from 'pocketbase'
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlexService {

  torrents$ = new BehaviorSubject<PBTorrentEntry[]>([]);

  baseApiUrl: string = "https://console.akkovachev.com/api/torrents"

  pbInstance: PocketBase = new PocketBase('https://pocketbase.akkovachev.com')
  constructor(private http: HttpClient, private alerts: AlertsService, private datePIpe: DatePipe) {
    this.loadPBCookie();
  }

  search(searchTerm: string): Promise<SearchEntry[]> {
    return this.http.post<SearchEntry[]>(`${this.baseApiUrl}/search`, {
      searchPhrase: searchTerm
    }).toPromise()
  }

  async loadPBCookie() {
    let readPBCookie = localStorage.getItem('pocketbase_auth');

    if(!readPBCookie) {
      let cookieCall = await this.http.get<{cookie: string}>(`${this.baseApiUrl}/pb/session`).toPromise()
      localStorage.setItem('pb_cookie_expiresAt', this.getCookieValueByName(cookieCall.cookie, 'Expires'))
      this.pbInstance.authStore.loadFromCookie(cookieCall.cookie)

      return 'OK'
    }

    return 'Cache'
  }

  async checkSession() {
    let checkExpireDate = localStorage.getItem('pb_cookie_expiresAt')

    if(checkExpireDate) {
        let todaysDate = Date.now()
        let cookieExpireDate = new Date(checkExpireDate).getTime();

        if(todaysDate > cookieExpireDate) {
          localStorage.removeItem('pocketbase_auth')
          await this.loadPBCookie()
        } else {
          return 'OK'
        }
    } else {
      await this.loadPBCookie()
    }
  }

  async startTorrentDownload(torrentId: string) {
    try {
      let startCall = await this.http.get(`${this.baseApiUrl}/download/${torrentId}`).toPromise()
    } catch(e) {
      console.log(e)
    }
  }

  async getTorrents(): Promise<PBTorrentEntry[]> {
    await this.checkSession();

    const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)

    let sevenDaysAgoString = this.datePIpe.transform(sevenDaysAgo, 'yyyy-MM-dd');

    return this.pbInstance.collection('torrents').getList(1, 50, {sort: '-created', filter: `created > "${sevenDaysAgoString}"`}).then(d => {
      this.torrents$.next(d.items)
      return d.items
    })
  }

 async subscribeToTorrentsCollection() {
    await this.checkSession();

    return this.pbInstance.collection('torrents').subscribe('*',(e)  => {

      let stateBeforeChange = this.torrents$.getValue();

      if(e.action === 'update') {
        this.alerts.addAlert(`${e.record.torrentName} chaged status to ${e.record.status}`)
        let changeAtIdx = stateBeforeChange.findIndex(t => t.id === e.record.id)
        stateBeforeChange[changeAtIdx] = e.record
      }
      if(e.action === 'create') {
        this.alerts.addAlert(`${e.record.torrentName} has been started`)
        stateBeforeChange.unshift(e.record);
        // this.torrents$.next()
      }
      if(e.action === 'delete') {
        let changeAtIdx = stateBeforeChange.findIndex(t => t.id === e.record.id)
        stateBeforeChange.splice(changeAtIdx, 1);
        this.alerts.addAlert(`${e.record.torrentName} has been deleted!`, 'warning')
      }
    });
  }

getCookieValueByName = (stringToLookIn: string, name: string) => {
    var match = stringToLookIn.match(new RegExp("(^| )" + name + "=([^;]+)"));
    return match ? match[2] : "";
}

}
