import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CrudContent, MetaOptions } from './auth/models/meta.model';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(private httpClient: HttpClient, private fb: FormBuilder) { }

  onAction$ = new Subject<CrudContent>();

  async loadData(data: CrudContent) {
    let meta: MetaOptions[]
    if(data.meta && data.meta.length) {
      meta = data.meta
      this.onAction$.next(data);
    } else {
      try {
        let loadMeta =  await this.httpClient.get<any>(environment.admin.meta.replace(':forEntity', data.entity)).toPromise()
        meta = loadMeta
        this.onAction$.next({...data, meta});
      } catch(e) {
        console.log(e)
      }
    }
  }
}
