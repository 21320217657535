import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-two-factor-auth-form',
  templateUrl: './two-factor-auth-form.component.html',
  styleUrls: ['./two-factor-auth-form.component.scss']
})
export class TwoFactorAuthFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
