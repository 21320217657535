import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, of, Subject, timer } from 'rxjs';
import { catchError, delay, delayWhen, map, retry, retryWhen, switchAll, take, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { AuthService } from './auth/auth.service';
export const WS_ENDPOINT = 'wss://youtube-api.akkovachev.com/progressws'

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  constructor(private http: HttpClient, private auth: AuthService) { }
// //https://blog.angular-university.io/rxjs-error-handling/
  private socket$;
  private messagesSubject$ = new BehaviorSubject([]);
  private timer;
  private pingInterval;
  public messages$ = this.messagesSubject$.pipe(switchAll(), catchError(e => { throw e }));

  /**
   * Creates a new WebSocket subject and send it to the messages subject
   * @param cfg if true the observable will be retried.
   */
  public connect(cfg: { reconnect: boolean } = { reconnect: false }): void {
    if (!this.socket$ || this.socket$.closed) {
      let wrapper = async () => {
        let getUserId = (await this.auth.getCurrentUser()).id
        this.socket$ = this.getNewWebSocket(getUserId);
        const messages = this.socket$.pipe(cfg.reconnect ? this.reconnect : o => o,
          tap({
            error: error => {
              console.log(error)
            },
          }), catchError(_ => EMPTY))
          //toDO only next an observable if a new subscription was made double-check this
          this.messagesSubject$.next(messages);
        }
        wrapper();
    }
  }

  /**
   * Retry a given observable by a time span
   * @param observable the observable to be retried
   */
  private reconnect(observable: Observable<any>): Observable<any> {
      return observable.pipe(
        retryWhen(errors => errors.pipe(
        tap(val =>  {
          console.log('[WebSocket] Try to reconnect', val)
        }), delay(3000), take(5))))
  }

  close() {
    if(this.socket$) {
      clearInterval(this.pingInterval)
      this.socket$.complete();
      this.socket$ = undefined;
      this.timer = undefined;
    }
  }

  sendMessage(msg: any) {
    if(this.socket$) {
      let appendUser = async () => {
        let userId = (await this.auth.getCurrentUser()).id
        let appendUserId = { ...msg, userId}
        this.socket$.next(JSON.stringify(appendUserId));
      }
      appendUser();
    }
  }

  /**
   * Return a custom WebSocket subject which reconnects after failure
   */
  private getNewWebSocket(userId: number) {
    return webSocket({
      url: WS_ENDPOINT,
      openObserver: {
        next: () => {
          clearTimeout(this.timer)
          this.timer = undefined;
            if(this.socket$) {
              this.pingInterval = setInterval(() => {
                this.sendMessage({ping: "ping"})
              }, 30000);
            }

          console.log('[WebSocket]: connection ok');
        }
      },
      closeObserver: {
        next: () => {
          console.log('[WebSocket]: connection closed');
          this.socket$ = undefined;
          clearTimeout(this.pingInterval)
          this.pingInterval = undefined;
          if(!this.timer) {
            this.messagesSubject$.next([{error: 'error'}])
            this.timer = setInterval(() => {
                this.connect({ reconnect: true });
              }, 3000)
          }
        }
      },
      protocol: [`userId~${userId}`]
    });
  }

  initiateDownload(request: any): Promise<any> {
    return this.http.post('https://youtube-api.akkovachev.com/api/initiate', request).toPromise()
  }

  async getDownloads() {
    let userId = await (await this.auth.getCurrentUser()).id
    return this.http.get<any[]>('https://youtube-api.akkovachev.com/api/downloads/' + userId).toPromise();
  }

  getListItemDetails(listId: string) {
    return this.http.get<any[]>('https://console.akkovachev.com/api/youtube/listitems/' + listId).toPromise();
  }

  getPlaylistDetails(listId: string) {
    return this.http.get<any[]>('https://console.akkovachev.com/api/youtube/list/' + listId).toPromise();
  }
}
