import { CompletedPlexTable } from './plex/completed-table/completed-table.component';
import { PlexTableComponent } from './plex/table/table.component';
import { PlexComponent } from './plex/plex.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './auth/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { TasksComponent } from './tasks/tasks.component';
import { DynamicFormComponent } from './tasks/form/dynamic-form/dynamic-form.component';
import { DynamicFormField } from './tasks/form/dynamic-form-field/dynamic-form-field.component';
import { SensorsComponent } from './sensors/sensors.component';
import { MiSensorComponent } from './sensors/mi-sensor/mi-sensor.component';
import { UsersComponent } from './users/users.component';
import { ActionComponent } from './users/action/action.component';
import { AlertsComponent } from './alerts/alerts.component';
import { CrudComponent } from './crud/crud.component';
import { MetaService } from './tasks/form/task-control.service';
import { YoutubeComponent } from './youtube/youtube.component';
import { TableComponent } from './youtube/table/table.component';

import { NgbdSortableHeader } from './sortable.directive';
import { CanSeeDirective } from './directives/can-see.directive';
import { InfoModalComponent } from './youtube/info-modal/info-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    TasksComponent,
    DynamicFormField,
    DynamicFormComponent,
    SensorsComponent,
    MiSensorComponent,
    UsersComponent,
    ActionComponent,
    AlertsComponent,
    CrudComponent,
    YoutubeComponent,
    TableComponent,
    PlexTableComponent,
    NgbdSortableHeader,
    CanSeeDirective,
    InfoModalComponent,
    PlexComponent,
    CompletedPlexTable,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AuthModule,
    NgbModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide : LocationStrategy , useClass: HashLocationStrategy},
    MetaService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
