import { HttpClient } from '@angular/common/http';
import { stringify } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { SensorReadingResponse } from '../models/Sensor';
import { Room, SensorResponse } from '../models/types';

@Injectable({
  providedIn: 'root'
})
export class TempService {

  baseUrl = 'https://console.akkovachev.com/api';

  constructor(private http: HttpClient) {}

  getTemperatureReadingForRoom(room: Room) {
    return this.http.get<SensorReadingResponse>(`${this.baseUrl}/temp/${room}`, ).toPromise().then(d => d.message);
  }

  getTemperatureSensors() {
    return this.http.get<SensorResponse[]>(`${this.baseUrl}/temp/sensors`).toPromise();
  }
}

