import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TaskService } from 'src/app/services/task.service';
import { UsersService } from 'src/app/users.service';
import { TaskFormBase } from '../task-base';
import { MetaService } from '../task-control.service';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {

  @Input() taskFields: TaskFormBase<string>[] = [];
  @Input() fromGroup : FormGroup
  @Input() actionType: string;

  form: FormGroup;
  payLoad = '';

  constructor( private tcs: MetaService, private taskService: TaskService, private usersService: UsersService) { }

  ngOnInit() {
    if(this.taskFields.length) {
      this.form = this.tcs.toFormGroup(this.taskFields);
    } else {
      this.form = this.fromGroup
    }
  }

  onSubmit() {
    this.payLoad = JSON.stringify(this.form.getRawValue());
    if(this.actionType) {
      // this.usersService.executeAction()
    } else {
       this.taskService.startTask(this.form.getRawValue())
    }
  }

}
