<table class="" *ngIf="tableData && tableData.length; else noData">
  <thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col" sortable="poster" ></th>
    <th scope="col" sortable="torrentName"  (sort)="onSort($event)">Име</th>
    <th scope="col" sortable="torrentSize"  (sort)="onSort($event)">Размер</th>
    <th scope="col" sortable="downloadedCount"  (sort)="onSort($event)">Сваляния</th>
    <th scope="col" sortable="activeSeeders" (sort)="onSort($event)">Сийдове</th>
    <th scope="col">Сваляне</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let record of tableRows; let i = index" >
    <th scope="row">{{ i+1 }}</th>
    <td  data-label="Poster">
    <img [src]="record.poster" class="poster">
    </td>
    <td  data-label="Име">
      <div class="flex-container">
        {{record.torrentName}}
        <img src="https://zamunda.net/pic/pic/z_icons/bgaudio.png" *ngIf="record.bgAudio">
        <img src="https://zamunda.net/pic/pic/z_icons/bgsubs.png" *ngIf="record.bgSubtitles">
      </div>
    </td>
    <td data-label="Размер">
      {{record.torrentSize}}
    </td>
    <td  data-label="Сваляния">
      {{record.downloadedCount}}
    </td>
    <td  data-label="Сийдове">
      {{ record.activeSeeders }}
    </td>
    <td  data-label="Сваляне">
      <button type="button" class="btn btn-info" (click)="download(record.torrentId)"> Свали</button>
     </td>
  </tr>
  </tbody>
</table>
<ng-template #noData>
  <div class="no-records">
    No Records found
  </div>
</ng-template>
