import { Component, OnInit } from '@angular/core';
import { AlertsService } from '../alerts.service';
import { AlertsComponent } from '../alerts/alerts.component';
import { TableBuilder } from '../helpers/tableBuilder';
import { IOnActionExecute } from '../models/action.model';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  constructor(private userService: UsersService, private alertsService: AlertsService) {
  }

  usersData;
  tableHeaders: any[];
  actions: any[]
  dataRef: any[]
  meta: any[]
  ngOnInit(): void {
    this.userService.getUsers()
    this.userService.users$.subscribe(d => {
      this.meta = d.meta
      let pureData = new TableBuilder(this.meta, d.data)
      this.tableHeaders = pureData.getHeaders();
      this.usersData = pureData.getData()
      this.actions = d.actions;

      this.dataRef = d.data
    });
  }

  cleanData(event: IOnActionExecute) {
    if(event.action === 'DELETE') {
      let idxToDelete = this.userService.users$.value.data.findIndex(e => e.id === event.forRecord.id)
      this.userService.users$.value.data.splice(idxToDelete, 1)
      this.userService.users$.next(this.userService.users$.value)
    }
    this.alertsService.addAlert(event.serverResponse.message)
  }
}
