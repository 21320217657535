<div class="sensor">
  <div class="overlay">
    <div class="onHover" (click)="getTemperature()">Вземи данни</div>
    <span class="roomName"> {{roomName}}</span>
      <span *ngIf="temperature">{{temperature}}  &deg;C</span>
      <span *ngIf="humidity">{{humidity}} %</span>
      <span *ngIf="voltage">{{voltage}} V</span>
    </div>
    <div class="loader" *ngIf="isLoading">Loading...</div>
</div>
