<h1>Play List Content</h1>
<table>
  <thead>
  <tr>
    <th scope="col">#</th>
    <th scope="col">Thumbnail</th>
    <th scope="col">Name</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let record of tableRows; let i = index">
    <th scope="row">
      {{i+1}}
    </th>
    <th scope="row">
      <div class="img-placeholder" >
        <img [src]="record.thumbnails.default.url" *ngIf="record.thumbnails && record.thumbnails.default"/>
      </div>
    </th>
    <td  data-label="Name">
      {{ record.title }}
    </td>
  </tr>
  </tbody>
</table>
