<div [formGroup]="form">
  <label [attr.for]="task.key">{{ task.label }}</label>

  <div [ngSwitch]="task.controlType">
    <input *ngSwitchCase="'textbox'"
           [formControlName]="task.key"
           [id]="task.key"
           [type]="task.type"
           class="form-control" />

    <select [id]="task.key"
            *ngSwitchCase="'dropdown'"
            [formControlName]="task.key"
            class="form-control">
      <option *ngFor="let opt of task.options"
              [value]="opt.key">
        {{ opt.value }}
      </option>
    </select>

    <input *ngSwitchCase="'checkbox'"
           [formControlName]="task.key"
           [id]="task.key"
           type='checkbox'
           class="form-control" />

  </div>

  <div class="errorMessage"
       *ngIf="!isValid">{{ task.label }} is required</div>
</div>
