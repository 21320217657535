<div class="flex plex">
  <h1>Zamunda Plex - Downloader</h1>

  <div class="input-group mb-3">
    <div class="input-group-prepend">
      <span class="input-group-text" id="basic-addon3">Search</span>
    </div>
    <input
      type="text"
      class="form-control"
      id="basic-url"
      aria-describedby="basic-addon3"
      name="serachPhrase"
      [(ngModel)]="plexSearch!.serachPhrase"
      (keyup.enter)="search()"
    />
  </div>

  <!-- <div class="res-table">
    <plex-table [tableData]="searchResults"></plex-table>
    <div class="spinner-border text-primary" role="status" *ngIf="isSearching">
      <span class="sr-only">Loading...</span>
    </div>
  </div> -->

  <div class="actions-list">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="collapseRecent.toggle()"
      aria-controls="collapseExample"
    >
      Search Results
    </button>
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="collapse.toggle()"
      aria-controls="collapseExample"
    >
      Completed Movies / Tv Shows
    </button>
  </div>

    <div #collapseRecent="ngbCollapse" [(ngbCollapse)]="isCollapsedSearchResult">
      <div class="card">
        <div class="res-table">
          <plex-table [tableData]="searchResults"></plex-table>
          <div
            class="spinner-border text-primary"
            role="status"
            *ngIf="isSearching"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsedProgress">
      <div class="card">
        <div class="card-body">
          <div
            class="progress-container"
          >
            <completed-pex-table [tableData]="torrentsData$ | async"></completed-pex-table>
            <!-- <pre>{{content}}</pre> -->
          </div>
        </div>
      </div>
    </div>
  </div>
