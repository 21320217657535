import { groupBy, keyBy } from "lodash";
export class TableBuilder {

  headers: any[];
  meta: any[];
  data: any[]

  dataKeys: string[]
  constructor(meta: any[], data: any[] ) {
    this.meta = meta.sort((a, b) => (a.order > b.order) ? 1 : -1)
    this.dataKeys = meta.map(e => e.label)
    this.data = data;
  }

  getHeaders(): string[] {
    return this.dataKeys;
  }

  getData() {
    let metaOrder = this.meta.map(e => e.property);
    let resultsFinal = []
    this.data.forEach((d) => {
      resultsFinal.push( this.sort(d, metaOrder));
    })

    return resultsFinal;
  }

  sort(object: any, ref: string[]) {
    let sortedData = []
    ref.forEach(e => {
      sortedData.push(object[e])
    })

    return sortedData;
  }
}
