<div class="flex youtube">
  <div class="socket-error" *ngIf="socketError">
    <p>
      It appears that the YouTube Download API server is down
    </p>
    <p>Please Try again later!</p>
  </div>
  <h1>Youtube Video / Mp3 Downloader</h1>
  <div class="youtube-form">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon3">YouTube Link</span>
      </div>
      <input type="text"
             class="form-control"
             id="basic-url"
             aria-describedby="basic-addon3"
             name="list"
             [(ngModel)]="youtubeData!.link">

    </div>
      <div class="input-group-text checkbox-mp3 mb-3">
        <label for="type">MP3 Only</label>
        <input type="checkbox"
               aria-label="Checkbox for following text input"
               name="type"
               id="type"
               [(ngModel)]="youtubeData!.type">
      </div>
      <div class="input-group-text checkbox-mp3 mb-3">
        <label for="syncWithMyDir">Sync With NextCloud</label>
        <input type="checkbox"
               aria-label="Checkbox for following text input"
               name="syncWithMyDir"
               id="syncWithMyDir"
               [(ngModel)]="youtubeData!.syncWithMyDir">
      </div>
    <div class="input-group mb-3" canSeeIfHasPermission="all">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon3">Folder (optional)</span>
      </div>
      <input type="text"
             class="form-control"
             id="basic-url"
             aria-describedby="basic-addon3"
             name="folder"
             [(ngModel)]="youtubeData!.folder">
    </div>
  </div>
  <button (click)="startConversion()"
          type="button"
          class="btn btn-success"
          [disabled]="inProgress">{{inProgress ? 'Processing...' : 'Start YouTube Extraction'}}</button>

  <button (click)="downloadFile()"
          type="button"
          class="btn btn-success mt-2"
          *ngIf="showDownload">Download</button>


  <div class="actions-list">
    <button type="button"
            class="btn btn-outline-primary"
            (click)="collapse.toggle()"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
      Live Log
    </button>
    <button type="button"
            class="btn btn-outline-primary"
            (click)="collapseRecent.toggle();loadRecentDownloads()"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapseExample">
      Recent Downloads
    </button>
    <div #collapse="ngbCollapse"
         [(ngbCollapse)]="isCollapsed">
      <div class="card">
        <div class="card-body">
          <div class="progress-container" #scrollableContainer [(scrollTop)]="scrollableContainer.scrollHeight">
            <pre>{{content}}</pre>
          </div>
        </div>
      </div>
    </div>

    <div #collapseRecent="ngbCollapse"
         [(ngbCollapse)]="isCollapsedRecent">
      <div class="card">
        <app-table [tableData]="tableData"></app-table>
      </div>
    </div>
  </div>
</div>
