import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudService } from 'src/app/crud.service';
import { Action, HttpMethodTypes, IOnActionExecute } from 'src/app/models/action.model';
import { UsersService } from 'src/app/users.service';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {
  @Input() label: string;
  @Input() actions: Action[]
  @Input() data: any;
  @Output() onActionExecute = new EventEmitter<IOnActionExecute>();

  constructor(private userService: UsersService, private crudService: CrudService) { }

  ngOnInit(): void {
  }

 async executeAction(action: Action) {
    let replace = action.endpoint.split(":");
    let finalUrl = action.endpoint
    let payLoad;

    if(action.entity) {
      this.crudService.loadData({entity: action.entity, values: this.data, endpoint: action.endpoint})
    } else {
      if(replace.length > 1) {
        finalUrl = action.endpoint.replace(":"+replace[1], this.buildExpectedPayload(action,this.data))
      } else {
        payLoad =  this.buildExpectedPayload(action,this.data)
      }

      try {
        let response = await this.userService.executeAction(action, finalUrl, payLoad)

        this.onActionExecute.emit({serverResponse: response, action: (action.httpType as HttpMethodTypes), forRecord: this.data})
      } catch(e) {
        console.log(e)
      }
    }

  }

  private buildExpectedPayload(action: Action, data: any) {
    let expectedPayloadArray = Object.keys(action.expectedPayload);

    let payLoad;
    if(action.httpType === "DELETE") {
      expectedPayloadArray.forEach(e => {
        if(action.expectedPayload[e] === 'param') {
            payLoad = data[e]
        }
      })
    } else if(action.httpType === 'POST'){
      let temp = {}
      expectedPayloadArray.forEach(e => {
        if(action.expectedPayload[e] === 'string') {
            temp[e] = data[e];
        }
      })

      payLoad = temp;
    }

    return payLoad
  }
}

