<table class="" *ngIf="tableData && tableData.length; else noData">
  <thead>
  <tr>
    <th scope="col">#</th>
    <!-- <th scope="col" sortable="poster" ></th> -->
    <th scope="col" sortable="torrentName"  (sort)="onSort($event)">Име</th>
    <th scope="col" sortable="torrentSize"  (sort)="onSort($event)">Тип</th>
    <th scope="col" sortable="downloadedCount"  (sort)="onSort($event)">Статус</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let record of tableRows; let i = index" >
    <th scope="row">{{ i+1 }}</th>
    <!-- <td  data-label="Poster">
    <img [src]="record.poster" class="poster">
    </td> -->
    <td  data-label="Име">
      <div class="flex-container">
        {{record.torrentName}}
      </div>
    </td>
    <td data-label="Тип">
      {{record.type}}
    </td>
    <td data-label="Статус">
      {{record.status}}
    </td>
  </tr>
  </tbody>
</table>
<ng-template #noData>
  <div class="no-records">
    No Records found
  </div>
</ng-template>
