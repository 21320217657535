<div class="loginContainer d-flex justify-content-center align-items-center flex-column">
  <img class="mb-4" src="https://freepikpsd.com/wp-content/uploads/2019/10/admin-png-Transparent-Images.png">
  <h1 class="h3 mb-3 font-weight-normal">Моля впишете се!</h1>
  <div class="loginForm">
    <form #loginForm="ngForm" (ngSubmit)="login()">
      <div class="form-group">
        <label for="username">Потребител</label>
        <input
          type="email"
          class="form-control"
          id="username"
          required
          email
          name="username"
          [(ngModel)]="userFormModel.username"
          #email="ngModel"
        />
        <div
          [hidden]="email.valid || email.pristine"
          class="alert alert-danger"
        >
          Моля въведете валиден имейл
        </div>
      </div>
      <div class="form-group">
        <label for="password">Парола</label>
        <input
          type="password"
          class="form-control"
          id="password"
          name="password"
          required
          minlength="8"
          [(ngModel)]="userFormModel.password"
          #password="ngModel"
        />
        <div
          [hidden]="password.valid || password.pristine"
          class="alert alert-danger"
        >
          Паролата трябва да бъде по-дълга от 8 символа
        </div>
      </div>
      <!-- <div class="form-group">
        <label for="password">Google Authenticator Code</label>
        <input
          type="text"
          class="form-control"
          id="TFAToken"
          name="TFAToken"
          required
          [(ngModel)]="userFormModel.TFAToken"
          #tfaToken="ngModel"
        />
      </div> -->
      <button
        type="submit"
        class="btn btn-success"
        [disabled]="!loginForm.form.valid"
      >
        Вход
      </button>
      <div [hidden]="!error" class="alert alert-danger">
        Грешка: {{ error }}
      </div>
    </form>
  </div>
</div>
